import React from "react";
import { Link } from "gatsby";
import { Waypoint } from "react-waypoint";

import "./ImageCta.scss";

const ImageCTA = ({ title, summary, linkLabel, link, image }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`image-cta waypoint ${active ? "waypoint-active" : ""}`}
        style={{ backgroundImage: `url('${image.fluid.src}')` }}
      >
        <div className="image-cta__content align-center">
          <h3 className="heading heading--l text--black">{title}</h3>
          <p className="text--l text--medium">{summary}</p>
          <Link
            to={`/${link}/`}
            className="button button-primary button-large button-round"
          >
            {linkLabel}
          </Link>
        </div>
      </div>
    </Waypoint>
  );
};
export default ImageCTA;
