import React from "react";
import "./TeamItem.scss";
import { Waypoint } from "react-waypoint";

const TeamItem = ({
  email,
  image,
  linkedin,
  video,
  name,
  position,
  summary,
  setVideo,
}) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  // console.log(name,position)

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div className={`team-item  waypoint ${active ? "waypoint-active" : ""}`}>
        <div className="team-item__media">
          <img
            className="image-rounded"
            src={image.fluid.src}
            alt="Team Item"
          />
        </div>
        <div className="team-item__content">
          <div className="team-item__content-inner">
            <h3>{name.text}</h3>
            <h5>{position}</h5>
            <p>{summary.text}</p>
            <ul className="social-links">
              <li>
                <a href={`mailto:${email}`} className="social-link">
                  <img src={require("./../assets/svg/email.svg")} alt="email" />
                  <span className="sr-only">Email</span>
                </a>
              </li>
              {linkedin.url && (
                <li>
                  <a
                    href={linkedin.url}
                    target="_blank"
                    className="social-link"
                  >
                    <img
                      src={require("./../assets/svg/linkedin.svg")}
                      alt="linkedin"
                    />
                    <span className="sr-only">Linked</span>
                  </a>
                </li>
              )}
              {video.embed_url && (
                <li>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setVideo(video.embed_url);
                    }}
                    className="social-link"
                  >
                    <img
                      src={require("./../assets/svg/play-btn.svg")}
                      alt="Video Reel"
                    />
                    <span className="sr-only">Video Reel</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Waypoint>
  );
};
export default TeamItem;
