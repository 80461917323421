import React from "react";
import "./FeaturedHero.scss";
import HeroAnimation from "./HeroAnimation";
import { useState } from "react";
import { useEffect } from "react";

const FeaturedHero = ({ title, description, type, label, image, buttons }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <div className={`featured-hero__wrapper ${active ? "active" : ""}`}>
      <div className="featured-hero__label verticle-text">
        <span>{label}</span>
      </div>
      <div className={`featured-hero ${type}`}>
        <div className="featured-hero__background">
          <HeroAnimation type={type} />
        </div>

        <div className="featured-hero__content">
          <div className="featured-hero__content-inner">
            <h1 className="heading heading--xl text--black">{title}</h1>
            <div
              className="text text--xl text--medium"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            {buttons ? (
              <div class="row no-gutters">
                <div className="col col-lg-4">
                  <a
                    className="button button-block button-round button-pink"
                    href="https://form.typeform.com/to/EaOM8Q"
                    target="_blank"
                  >
                    New Business
                  </a>
                </div>
                <div className="col col-lg-4">
                  <a
                    className="button button-block button-round button-gray"
                    href="https://Colormatics.drift.click/09170d44-b76e-4bda-993a-8b2fdad06b30"
                    target="_blank"
                  >
                    Careers
                  </a>
                </div>
                <div className="col col-lg-4">
                  <a
                    className="button button-block button-round button-gray"
                    href="https://Colormatics.drift.click/09170d44-b76e-4bda-993a-8b2fdad06b30"
                    target="_blank"
                  >
                    Questions
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedHero;
