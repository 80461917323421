import React, { useEffect, useRef } from "react";
import Float from "./../lib/Float";

const Process = () => {
  const green = useRef(null);
  const pink = useRef(null);
  const line = useRef(null);

  useEffect(() => {
    Float(pink.current, 0.5);
    Float(green.current, 2);
    Float(line.current, 1);
  });

  return (
    <svg
      className="shapes"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1762.027 1809.208"
    >
      <path
        ref={pink}
        d="M656.78 828.118a126 126 0 01-86.096-92.868l-66.11-294.183a126 126 0 0136.466-119.273l219.313-206.92A126 126 0 01883.66 86.025l282.61 86.403a126 126 0 0186.096 92.868l66.11 294.183a126 126 0 01-36.466 119.274l-219.313 206.92a126 126 0 01-123.307 28.847z"
        fill="#c51882"
      />
      <path
        ref={green}
        d="M281.174 969.85a126 126 0 01-85.933-92.15l-66.823-289.445a126 126 0 0136.838-120.494l217.255-202.594a126 126 0 01122.77-28.343l284.08 86.851a126 126 0 0185.931 92.15l66.824 289.446a126 126 0 01-36.838 120.494l-217.253 202.594a126 126 0 01-122.773 28.343z"
        style={{ "mix-blend-mode": "multiply", isolation: "isolate" }}
        fill="#7fbebc"
      />
      <g ref={line} fill="none">
        <path d="M732.946 1582.232a126 126 0 01-85.935-92.151l-94.598-409.826A126 126 0 01589.25 959.77l307.599-286.867a126 126 0 01122.773-28.343l402.176 122.957a126 126 0 0185.934 92.152l94.598 409.825a126 126 0 01-36.836 120.486l-307.598 286.866a126 126 0 01-122.773 28.343z" />
        <path
          d="M1135.999 1702.32a123.008 123.008 0 0031.936 5.312 123.082 123.082 0 0061.54-14.209 123.01 123.01 0 0026.375-18.775l307.597-286.862a123.01 123.01 0 0020.565-25 123.087 123.087 0 0018.464-60.392 123.01 123.01 0 00-3.07-32.225l-94.598-409.826a123.009 123.009 0 00-11.367-30.314 123.083 123.083 0 00-43.073-46.192 123.013 123.013 0 00-29.447-13.455l-402.176-122.958a122.996 122.996 0 00-31.936-5.31 123.096 123.096 0 00-61.54 14.209 123 123 0 00-26.375 18.774L591.297 961.959a123.01 123.01 0 00-20.565 25 123.086 123.086 0 00-18.464 60.392c-.402 10.817.63 21.66 3.07 32.225l94.598 409.826a123.01 123.01 0 0011.367 30.314 123.084 123.084 0 0043.073 46.192 123.008 123.008 0 0029.447 13.455l402.176 122.958m-.877 2.869l-402.176-122.958c-43.05-13.162-75.808-48.292-85.933-92.156l-94.598-409.825c-10.124-43.859 3.918-89.787 36.836-120.486l307.597-286.862c32.92-30.701 79.727-41.508 122.774-28.347.002 0-.002-.001 0 0l402.176 122.957c43.05 13.162 75.808 48.292 85.933 92.156l94.598 409.825c10.124 43.859-3.918 89.787-36.836 120.486l-307.597 286.862c-32.922 30.703-79.724 41.51-122.774 28.348z"
          fill="#f8f9f8"
        />
      </g>
    </svg>
  );
};

const Services = () => {
  const green = useRef(null);
  const pink = useRef(null);
  const line = useRef(null);

  useEffect(() => {
    Float(pink.current, 0.5);
    Float(green.current, 2);
    Float(line.current, 1);
  });
  return (
    <svg
      className="shapes"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1263.343 1981.134"
    >
      <path
        ref={green}
        d="M1084.454 1881.135l-431.607-123.761a200 200 0 01-137.124-247.38l123.76-431.606 431.607 123.76a200 200 0 01137.125 247.38l-123.761 431.607z"
        fill="#7fbebc"
      />
      <path
        ref={pink}
        d="M829.64 1527.857l-450.832-129.274a200 200 0 01-137.125-247.38l128.999-449.87 450.831 129.274a200 200 0 01137.125 247.38l-128.998 449.87z"
        style={{ "mix-blend-mode": "multiply", isolation: "isolate" }}
        fill="#c41282"
      />
      <g ref={line} stroke="#f7f7f7" stroke-width="3" fill="none">
        <path
          d="M1114.446 248.349L921.224 922.193a200 200 0 01-247.38 137.125L0 866.097l193.222-673.845a200 200 0 01247.38-137.125l673.844 193.222z"
          stroke="none"
        />
        <path d="M1112.177 250.82L919.782 921.78a198.5 198.5 0 01-245.524 136.096L3.298 865.482a1.5 1.5 0 01-1.03-1.856l192.396-670.96A198.5 198.5 0 01440.188 56.569l670.96 192.395a1.5 1.5 0 011.03 1.855z" />
      </g>
    </svg>
  );
};

const Team = () => {
  const green = useRef(null);
  const pink = useRef(null);
  const line = useRef(null);

  useEffect(() => {
    Float(pink.current, 0.5);
    Float(green.current, 2);
    Float(line.current, 1);
  });
  return (
    <svg
      className="shapes"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1764.563 1835.443"
    >
      <path
        ref={green}
        d="M1675.591 870.709L862.846 164.2a117.889 117.889 0 00-166.317 11.63L456.543 451.903a117.889 117.889 0 0011.635 166.311l90.394 78.578a117.889 117.889 0 0111.63 166.314l-222.04 255.428a117.891 117.891 0 0011.622 166.322l276.073 239.987a117.891 117.891 0 00166.32-11.639l222.041-255.428a117.889 117.889 0 01166.314-11.63l90.394 78.58a117.889 117.889 0 00166.314-11.631l239.982-276.068a117.889 117.889 0 00-11.63-166.318z"
        fill="#bd0a7e"
      />
      <path
        ref={pink}
        d="M1089.086 495.573L575.244 48.897a74.533 74.533 0 00-105.15 7.352l-151.725 174.54a74.533 74.533 0 007.352 105.148l57.15 49.68a74.533 74.533 0 017.353 105.148l-140.38 161.49a74.534 74.534 0 007.348 105.152l174.541 151.727a74.534 74.534 0 00105.153-7.358l140.38-161.489a74.533 74.533 0 01105.149-7.352l57.15 49.68a74.533 74.533 0 00105.148-7.354l151.725-174.54a74.533 74.533 0 00-7.352-105.148z"
        style={{ "mix-blend-mode": "multiply", isolation: "isolate" }}
        fill="#80bebc"
      />
      <path
        ref={line}
        d="M752.322 1736.904l531.806-633.782a90.57 90.57 0 00-11.164-127.598l-215.28-180.642a90.57 90.57 0 00-127.598 11.164l-59.148 70.49a90.57 90.57 0 01-127.598 11.163L544.157 720.564a90.571 90.571 0 00-127.603 11.16l-180.642 215.28a90.571 90.571 0 0011.169 127.602l199.184 167.135a90.57 90.57 0 0111.163 127.598l-59.148 70.49a90.57 90.57 0 0011.163 127.597l215.28 180.642a90.57 90.57 0 00127.599-11.164z"
        fill="none"
        stroke="#f7f7f7"
        stroke-width="3"
      />
    </svg>
  );
};

const Contact = () => {
  const green = useRef(null);
  const pink = useRef(null);
  const line = useRef(null);

  useEffect(() => {
    Float(pink.current, 0.5);
    Float(green.current, 2);
    Float(line.current, 1);
  });
  return (
    <svg
      className="shapes"
      xmlns="http://www.w3.org/2000/svg"
      width="1484.548"
      height="1227.338"
      viewBox="0 0 1484.548 1227.338"
    >
      <path
        ref={green}
        d="M1094.025 421.521c38.66-10.35 74.03 25.02 63.68 63.68l-175.397 655.115c-10.354 38.673-58.692 51.63-87 23.321L415.588 683.92c-28.308-28.309-15.35-76.647 23.322-87z"
        fill="#7bb8b6"
      />
      <path
        ref={pink}
        d="M520.683 973.739c-20.024 34.617-70 34.617-90.023 0L125.014 445.363c-20.053-34.667 4.963-78.038 45.012-78.038h611.291c40.049 0 65.065 43.371 45.012 78.038z"
        style={{ "mix-blend-mode": "multiply", isolation: "isolate" }}
        fill="#c51882"
      />
      <g ref={line} fill="none">
        <path d="M724.658 767.22c-2.102 39.965-46.671 62.675-80.24 40.886L75.563 438.859c-33.58-21.798-30.967-71.774 4.704-89.949L684.743 40.905c35.67-18.175 77.639 9.084 75.536 49.063z" />
        <path
          d="M694.975 808.148c7.476-3.81 13.87-9.443 18.492-16.293a47.868 47.868 0 005.673-11.469 49.864 49.864 0 002.522-13.323L757.283 89.81c.242-4.6-.131-9.148-1.11-13.519a47.868 47.868 0 00-4.442-12.003c-3.88-7.298-9.65-13.572-16.689-18.144-7.039-4.572-15.117-7.293-23.362-7.87a47.867 47.867 0 00-12.772.822 49.866 49.866 0 00-12.803 4.481L81.629 351.583a49.866 49.866 0 00-11.15 7.723 47.867 47.867 0 00-8.173 9.85c-4.38 7.01-6.926 15.146-7.364 23.527-.438 8.382 1.246 16.738 4.87 24.166a47.868 47.868 0 007.1 10.65 49.863 49.863 0 0010.284 8.843L646.051 805.59a49.864 49.864 0 0012.26 5.79 47.868 47.868 0 0012.614 2.152c8.258.287 16.574-1.575 24.05-5.384m1.362 2.673c-15.6 7.95-35.135 8.18-51.92-2.715L75.563 438.859c-33.58-21.797-30.966-71.773 4.704-89.949L684.743 40.905c35.67-18.175 77.639 9.085 75.536 49.064L724.658 767.22c-1.051 19.983-12.72 35.652-28.32 43.601z"
          fill="#f7f7f7"
        />
      </g>
    </svg>
  );
};

const HeroAnimation = ({ type }) => {
  switch (type) {
    case "process":
      return <Process />;
    case "expertise":
      return <Services />;
    case "team":
      return <Team />;
    case "contact":
      return <Contact />;
    default:
      return null;
  }
};

export default HeroAnimation;
