import React, { useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import Layout from "../components/layout";
import SEO from "../components/seo";

import FeaturedHero from "../components/FeaturedHero";
import TeamItem from "../components/TeamItem";
import ImageCTA from "../components/ImageCTA";
import FeaturedContent from "../components/FeaturedContent";
import { CSSTransition } from "react-transition-group";

import VideoModal from "./../components/VideoModal";

export const query = graphql`
  {
    allPrismicTeamPage {
      edges {
        node {
          id
          data {
            title {
              text
            }
            team_members {
              email
              linkedin {
                url
              }
              video {
                embed_url
              }
              image {
                url
                alt
                fluid {
                  src
                }
              }
              name {
                text
              }
              position
              summary {
                text
              }
            }
            intro_title {
              text
            }
            intro_summary {
              text
            }
            intro_subtitle {
              text
            }
            description {
              text
            }
            cta_link {
              uid
              type
              url
            }
            cta_title {
              text
            }
            cta_summary {
              text
            }
            cta_link_label
            cta_image {
              url
              fluid {
                src
              }
            }
            meta_description
            meta_title
            canonical {
              uid
              type
            }
          }
        }
      }
    }
  }
`;

const TeamPage = (props) => {
  const { location } = props;

  const { uid, data } = props.data.allPrismicTeamPage.edges[0].node;
  const items = data.team_members;
  const meta = {
    descripition: data.meta_description,
    title: data.meta_title,
    uid: data.canonical.uid,
    pageType: data.canonical.type,
  };

  const [video, setVideo] = useState(null);

  return (
    <>
      <Layout location={location}>
        <SEO meta={meta} description={meta.descripition} title={meta.title} uid={meta.uid} pageType={meta.pageType} />
        <div className="team page">
          <FeaturedHero
            label="team"
            title={data.title.text}
            description={data.description.text}
            type="team"
            image={require("./../assets/svg/hero-team.svg")}
          />
          <FeaturedContent
            label={data.intro_subtitle.text}
            large={data.intro_title.text}
            small={data.intro_summary.text}
          />
          <div className="container">
            <div className="team-members">
              {items.map((member, index) => {
                return (
                  <TeamItem
                    setVideo={(url) => setVideo(url)}
                    key={`teamember_${index}`}
                    {...member}
                  />
                );
              })}
            </div>
          </div>
          <ImageCTA
            title={data.cta_title.text}
            summary={data.cta_summary.text}
            link={data.cta_link.uid}
            linkLabel={data.cta_link_label}
            image={data.cta_image}
          />
        </div>
      </Layout>
      <CSSTransition
        in={video !== null}
        timeout={{ enter: 300, exit: 300 }}
        unmountOnExit
        classNames="modal-animation"
      >
        <VideoModal close={() => setVideo(null)} videoURL={video} />
      </CSSTransition>
    </>
  );
};

TeamPage.query = query;

export default withPreview(TeamPage);
