import React from "react";
import "./FeaturedContent.scss";
import { Waypoint } from "react-waypoint";

const FeaturedContent = ({ label, large, medium, small }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };
  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`featured-content waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <div className="row">
          <div className="col col-md-offset-3 col-md-6">
            <div className="header-group seperator align-center">
              <h4 className="heading heading--xs text-regular uppercase">
                {label}
              </h4>
              {large && (
                <h6 className="heading heading--l text--medium">{large}</h6>
              )}
              {medium && <p className="text--xl text--medium">{medium}</p>}
              {small && <p>{small}</p>}
            </div>
          </div>
        </div>
      </div>
    </Waypoint>
  );
};
export default FeaturedContent;
