import React from "react";
import "./VideoModal.scss";
import VideoPlayer from "./VideoPlayer";

export default class extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // console.log(this.props)
    return (
      <div className="video-modal">
        <div className="video-modal__inner">
          <div
            className="video-modal__close"
            onClick={() => {
              if (this.props.close) {
                this.props.close();
              }
            }}
          ></div>
          <VideoPlayer videoURL={this.props.videoURL} />
        </div>
      </div>
    );
  }
}
